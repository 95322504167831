import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import styled from "styled-components"
import { MDXRenderer } from "gatsby-plugin-mdx"

import GlobalStateProvider from "../context/provider"
import ContentWrapper from "../styles/contentWrapper"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { seoTitleSuffix } from "../../config"

import InApp from 'detect-inapp';

const StyledSection = styled.section`
  width: 100%;
  max-width: 62.5rem;
  margin: 0 auto;
  padding: 0 2.5rem;
  height: auto;
  background: ${({ theme }) => theme.colors.background};
  h1 {
    font-size: 1.5rem;
  }
  h2 {
    font-size: 1.25rem;
  }
  h3 {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    max-width: 36rem;
    margin: 0;
    padding: 0;
    height: 100%;
  }
`

const DoSomeTest = ({ data }) => {
  const [browserInfo, setBroserInfo] = React.useState({})

  React.useEffect(() => {
   doBrowserCheck()
    .then(data => setBroserInfo(data))
  }, [])

  return (
      <div>
        <h1>Browser Test</h1>
        {
            (function () {
                let r = []
                for (let info in browserInfo) {
                    r.push(
                        <div key={info}>{info} : {browserInfo[info]+''}</div>
                    )
                }

                return r
            })()
        }
      </div>
  )
}

const doBrowserCheck = async () => {
    let isPrivateMode = await doPrivateCheck()
    return {
        ...doInAppCheck(),
        isPrivateMode,
    }
}

function doInAppCheck() {
    let ret = {}

    try {
        const inapp = new InApp(window.navigator.userAgent || window.navigator.vendor || window.opera);
        ret.isMobile = inapp.isMobile
        ret.isDesktop = inapp.isDesktop
        ret.isInApp = inapp.isInApp
        ret.browser = inapp.browser
        ret.ua = inapp.ua
    } catch (err) {
        ret.isMobile = undefined
        ret.isDesktop = undefined
        ret.isInApp = undefined
        ret.browser = 'unknown'
        ret.ua = 'unknown'
    }

    try {
    function isWebview() {
        var useragent = window.navigator.userAgent;
        var rules = ['WebView','(iPhone|iPod|iPad)(?!.*Safari\/)','Android.*(wv|\.0\.0\.0)'];
        var regex = new RegExp(`(${rules.join('|')})`, 'ig');
        return Boolean(useragent.match(regex));
    }
        ret.isWebview = isWebview()

    } catch(err) {
        ret.isWebview = undefined
    }

    try {
        ret.isInFBorLine = (window.navigator.userAgent.indexOf("Line") > -1) || (window.navigator.userAgent.indexOf("FBAV") > -1) || (window.navigator.userAgent.indexOf("FBAN")>-1)
    } catch (err) {
        ret.isInFBorLine = undefined
    }

    return ret
}

function doPrivateCheck() {
  return new Promise(function detect(resolve) {
    var yes = function() { resolve(true); }; // is in private mode
    var not = function() { resolve(false); }; // not in private mode

    function detectChromeOpera() {
      // https://developers.google.com/web/updates/2017/08/estimating-available-storage-space
      var isChromeOpera = /(?=.*(opera|chrome)).*/i.test(navigator.userAgent) && navigator.storage && navigator.storage.estimate;
      if (isChromeOpera) {
        navigator.storage.estimate().then(function(data) {
          return data.quota < 120000000 ? yes() : not();
        });
      }
      return !!isChromeOpera;
    }

    function detectFirefox() {
      var isMozillaFirefox = 'MozAppearance' in document.documentElement.style;
      if (isMozillaFirefox) {
        if (indexedDB == null) yes();
        else {
          var db = indexedDB.open('inPrivate');
          db.onsuccess = not;
          db.onerror = yes;
        }
      }
      return isMozillaFirefox;
    }

    function detectSafari() {
      var isSafari = navigator.userAgent.match(/Version\/([0-9\._]+).*Safari/);
      if (isSafari) {
        var testLocalStorage = function() {
          try {
            if (localStorage.length) not();
            else {
              localStorage.setItem('inPrivate', '0');
              localStorage.removeItem('inPrivate');
              not();
            }
          } catch (_) {
            // Safari only enables cookie in private mode
            // if cookie is disabled, then all client side storage is disabled
            // if all client side storage is disabled, then there is no point
            // in using private mode
            navigator.cookieEnabled ? yes() : not();
          }
          return true;
        };

        var version = parseInt(isSafari[1], 10);
        if (version < 11) return testLocalStorage();
        try {
          window.openDatabase(null, null, null, null);
          not();
        } catch (_) {
          yes();
        }
      }
      return !!isSafari;
    }

    function detectEdgeIE10() {
      var isEdgeIE10 = !window.indexedDB && (window.PointerEvent || window.MSPointerEvent);
      if (isEdgeIE10) yes();
      return !!isEdgeIE10;
    }

    // when a browser is detected, it runs tests for that browser
    // and skips pointless testing for other browsers.
    if (detectChromeOpera()) return;
    if (detectFirefox()) return;
    if (detectSafari()) return;
    if (detectEdgeIE10()) return;
    
    // default navigation mode
    return not();
  });
}

export default DoSomeTest

export const pageQuery = graphql`
  {
    privacy: allMdx(filter: { fileAbsolutePath: { regex: "/privacy/" } }) {
      edges {
        node {
          body
          frontmatter {
            title
            seoTitle
            useSeoTitleSuffix
            useSplashScreen
          }
        }
      }
    }
  }
`
